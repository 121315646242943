import { NOTIFICATIONS } from "@/helpers/enums";

export default class Message {
  constructor() {
    this.id = null;
    this.title = null;
    this.body = null;
    this.importance = NOTIFICATIONS.IMPORTANCE.LOW;
    this.receivers = [];
    this.messageType = NOTIFICATIONS.TYPES.EMAIL;
    this.description = null;
  }
}
