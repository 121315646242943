var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"value":_vm.value,"persistent":"","max-width":"600px"}},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-card',{attrs:{"disabled":_vm.loading,"loading":_vm.loading}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t("hr.message.send_message"))+" "),_c('v-spacer'),_c('v-btn',{attrs:{"depressed":"","icon":""},on:{"click":_vm.onCancelClick}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',{staticClass:"pt-3"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('hr.message.receivers'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('employee-picker',{attrs:{"multiple":true,"label":_vm.$t('hr.message.receivers'),"error-messages":errors},model:{value:(_vm.message.receivers),callback:function ($$v) {_vm.$set(_vm.message, "receivers", $$v)},expression:"message.receivers"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-label',[_vm._v(_vm._s(_vm.$t("hr.message.message_type")))]),_c('v-radio-group',{attrs:{"row":"","mandatory":""},model:{value:(_vm.message.messageType),callback:function ($$v) {_vm.$set(_vm.message, "messageType", $$v)},expression:"message.messageType"}},_vm._l((_vm.$helpers.fromEnumToArray(_vm.NOTIFICATIONS.TYPES)),function(type){return _c('v-radio',{key:type.value,attrs:{"value":type.value,"color":"primary","label":_vm.$t(("hr.message.message_type_enum." + (type.text)))}})}),1)],1),(_vm.message.messageType !== _vm.NOTIFICATIONS.TYPES.SMS)?_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('hr.message.title'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"color":"tertiary","label":_vm.$t('hr.message.title'),"error-messages":errors},model:{value:(_vm.message.title),callback:function ($$v) {_vm.$set(_vm.message, "title", $$v)},expression:"message.title"}})]}}],null,true)})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('hr.message.body'),"rules":{ required: true, max: _vm.NOTIFICATIONS.COUNTS[_vm.message.messageType] }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"rows":"5","color":"tertiary","counter":_vm.NOTIFICATIONS.COUNTS[_vm.message.messageType],"label":_vm.$t('hr.message.body'),"error-messages":errors},model:{value:(_vm.message.body),callback:function ($$v) {_vm.$set(_vm.message, "body", $$v)},expression:"message.body"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-label',[_vm._v(_vm._s(_vm.$t("hr.message.importance")))]),_c('v-radio-group',{attrs:{"row":"","mandatory":""},model:{value:(_vm.message.importance),callback:function ($$v) {_vm.$set(_vm.message, "importance", $$v)},expression:"message.importance"}},_vm._l((_vm.$helpers.fromEnumToArray(_vm.NOTIFICATIONS.IMPORTANCE)),function(type){return _c('v-radio',{key:type.value,attrs:{"value":type.value,"color":"primary","label":_vm.$t(("hr.message.message_importance_enum." + (type.text)))}})}),1)],1)],1)],1),_c('v-card-actions',{staticClass:"dialog-footer"},[_c('v-spacer'),_c('v-btn',{attrs:{"loading":_vm.loading,"depressed":""},on:{"click":function($event){$event.stopPropagation();return handleSubmit(_vm.onSaveClick)}}},[_vm._v(_vm._s(_vm.$t("buttons.send")))])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }